import { useTranslation } from 'react-i18next';
import { Flex, Input, ScrollArea, Skeleton } from '@mantine/core';
import { names, useSpy } from 'services/espionage';
import { ImageMiniature } from 'components/Image';
import { usePhotoContext } from 'contexts/ItemPhotoContext';

interface ImageEventProps {
  disabled?: boolean;
}

export const testIds = {
  wrapper: 'image-event-wrapper',
  loadingMiniature: 'image-event-loading-miniature',
};

export const ImageEvent = ({ disabled = false }: ImageEventProps) => {
  const { t } = useTranslation();
  const { spyClick } = useSpy();
  const { imageData, deleteImage, openEditor, loadingNewReport, loadingUpload, containerId } = usePhotoContext();
  const showSkeleton = loadingNewReport || loadingUpload;
  const showContent = imageData.length > 0 || showSkeleton;

  const onEdit = (imageId: string) => {
    spyClick(names.Container.EditPhoto, { containerId, fileId: imageId });
    openEditor(imageId);
  };

  const onDelete = (imageId: string) => {
    spyClick(names.Container.DeletePhoto, { containerId, fileId: imageId });
    deleteImage(imageId);
  };

  return (
    <Input.Wrapper label={t('common.photos')} hidden={!showContent}>
      <ScrollArea>
        <Flex direction='row-reverse' justify='flex-end' gap='tn' w='100%' data-testid={testIds.wrapper}>
          {imageData.map((image) => (
            <ImageMiniature
              id={image.id}
              key={image.id}
              image={image.editedBase64 || image.originalBase64}
              onDelete={() => onDelete(image.id)}
              onEdit={() => onEdit(image.id)}
              disabled={disabled || loadingUpload}
            />
          ))}
          {showSkeleton && (
            <Skeleton className='shrink-0' w={100} h={100} radius='md' data-testid={testIds.loadingMiniature} />
          )}
        </Flex>
      </ScrollArea>
    </Input.Wrapper>
  );
};
