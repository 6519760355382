import { useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { Container, ElementData, ReportedValue } from '@flow/flow-backend-types';
import { names, useSpy } from 'services/espionage';
import {
  DateEvent,
  ButtonsEvent,
  NumericEvent,
  TextEvent,
  DropdownEvent,
  MultiSelectEvent,
  EventProps,
  InputEventProps,
  MultiSelectEventProps,
  AppendTextEvent,
  AppendTextEventProps,
} from 'components';
import { EventUIData } from 'stores/uiEvent';
import { getReportCollectionKey, useReporter, useReportStore } from 'stores/report';
import { ExecutionRouteParams } from 'routes/routes.config';
import { useGetValidationByEventId } from 'stores/uiEvent/uiEvent.selectors';
import { useDistraction } from 'stores/app';
import { EventTitle } from './EventTitle';

interface RenderEventProps {
  container: Container;
  uiEvent: EventUIData;
  onReportValueChange?: (value?: ReportedValue) => void;
  disabled?: boolean;
}

export const testIds = { optionalEvent: 'optional-event' };

export const RenderEvent = ({ container, uiEvent, disabled, onReportValueChange }: RenderEventProps) => {
  const { id: containerId } = container;
  const { eventId, title: eventTitle, isMandatory } = uiEvent;
  const reportKey = getReportCollectionKey(containerId, eventId);
  const { spyClick } = useSpy();
  const { executionId } = useParams() as ExecutionRouteParams;
  const validation = useGetValidationByEventId(containerId, eventId);
  const { distracting, distract, concentrate } = useDistraction(`event-edit:${eventId}`);
  const { reports } = useReportStore(['reports']);
  const { lastReport, triggerReport } = useReporter({ executionId, containerId, eventId });

  const triggerFocus = () => {
    spyClick(names.Container.ManualEditEvent, { containerId, eventId });
    distract();
  };

  const triggerBlur = (value?: ReportedValue) => {
    spyClick(names.Container.ManualEditEventDone, { containerId, eventId, value });
    concentrate();
  };

  function getEventProps<T extends ElementData>(elementData: T): EventProps<T> {
    return {
      title: <EventTitle eventTitle={eventTitle} isMandatory={isMandatory} />,
      reportKey,
      eventUiMeta: elementData,
      lastEventReport: lastReport,
      validation,
      disabled,
      triggerReport,
    };
  }

  function getInputEventProps<T extends ElementData>(elementData: T): InputEventProps<T> {
    return {
      ...getEventProps(elementData),
      isEditing: distracting,
      triggerFocus,
      triggerBlur,
    };
  }

  function getMultiSelectEventProps<T extends ElementData>(elementData: T): MultiSelectEventProps<T> {
    return {
      ...getEventProps(elementData),
      reports,
    };
  }

  function getAppendTextEventProps<T extends ElementData>(elementData: T): AppendTextEventProps<T> {
    return {
      ...getInputEventProps(elementData),
      reports,
    };
  }

  useEffect(() => {
    onReportValueChange?.(lastReport?.reportedValue);
  }, [lastReport]);

  function getEventUiComponent(event: EventUIData) {
    switch (event.type) {
      case 'ButtonsEvent':
        return <ButtonsEvent {...getEventProps(event.elementData)} />;
      case 'DropdownEvent':
        return <DropdownEvent {...getEventProps(event.elementData)} />;
      case 'MultiSelectEvent':
        return <MultiSelectEvent {...getMultiSelectEventProps(event.elementData)} />;
      case 'DateEvent':
        return <DateEvent {...getInputEventProps(event.elementData)} />;
      case 'TextEvent':
        return <TextEvent {...getInputEventProps(event.elementData)} />;
      case 'NumericEvent':
        return <NumericEvent {...getInputEventProps(event.elementData)} />;
      case 'AppendTextEvent':
        return <AppendTextEvent {...getAppendTextEventProps(event.elementData)} />;
      default:
        return null;
    }
  }

  return useMemo(() => getEventUiComponent(uiEvent), [reportKey, lastReport, disabled, distracting]);
};
