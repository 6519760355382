import { useMemo } from 'react';
import { formatPlaceholder } from 'utils';
import { dateFormatValues } from './settings.const';
import { useSettingsStore } from './settings.store';

/**
 * Get date format string based on user settings
 * @returns uppercase date format, compatible with dayjs
 */
export function useUserDateFormat() {
  const settings = useSettingsStore((state) => state.settings);
  const formatObject = dateFormatValues[settings.dateFormat];
  return useMemo(() => formatPlaceholder(formatObject), [formatObject]);
}

export function useLanguage() {
  return useSettingsStore((state) => state.settings.language);
}
