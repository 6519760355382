import { Drawer, Flex, Stack, Title } from '@mantine/core';
import { IconWorld } from '@tabler/icons-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { names, useSpy } from 'services/espionage';
import { modalManager } from 'services/modalManager';
import { SettingsKeys, useSettingsStore } from 'stores/settings';
import { ROUTES } from 'routes/routes.config';
import { AppInfo } from './components/AppInfo';
import {
  appInfoTestIds,
  DateFormatSelect,
  dateFormatSelectTestIds,
  LanguageSelect,
  languageSelectTestIds,
} from './components';

export const testIds = {
  drawerContent: 'settings-drawer-content',
  settingsListContainer: 'settings-page-list-container',
  cancelButton: 'settings-page-cancel',
  saveButton: 'settings-page-save',
  closeDrawerButton: 'settings-page-close-drawer',
  option: 'settings-page-option',
  appInfo: appInfoTestIds,
  language: languageSelectTestIds,
  dateFormat: dateFormatSelectTestIds,
};

interface SettingsDrawerProps {
  open: boolean;
  onClose: () => void;
}

export const SettingsDrawer = ({ open, onClose }: SettingsDrawerProps) => {
  const { spyClick, spyMount, spyUnmount } = useSpy();
  const { t } = useTranslation();
  const { getCurrentLanguage, updateSettings, settings } = useSettingsStore([
    'settings',
    'updateSettings',
    'getCurrentLanguage',
  ]);

  useEffect(() => {
    if (open) spyMount(names.SettingsDrawer.self);
  }, [open]);

  const handleChange =
    <Key extends SettingsKeys>(key: Key) =>
    (value: (typeof settings)[Key]) => {
      if (key === 'dateFormat') {
        spyClick(names.SettingsDrawer.SelectDateFormat, value);
      }
      updateSettings({ [key]: value });
    };

  const handleLanguageChange = (value: string) => {
    spyClick(names.SettingsDrawer.SelectLanguage, value);
    const onConfirm = () => {
      spyClick(names.LanguageModal.Save);
      handleChange('language')(value);
      window.location.replace(ROUTES.ROOT);
    };
    if (value !== getCurrentLanguage()) {
      modalManager.info({
        icon: IconWorld,
        title: t('settings.languageChange.title'),
        message: t('settings.languageChange.confirmationMessage'),
        labels: { confirm: t('settings.languageChange.confirmButton'), cancel: t('common.cancel') },
        onConfirm,
        onOpen: () => spyMount(names.LanguageModal.self),
        onCancel: () => spyClick(names.LanguageModal.Cancel),
        onClose: () => spyUnmount(names.LanguageModal.Close),
      });
    }
  };

  const handleClose = () => {
    spyClick(names.SettingsDrawer.Close);
    onClose();
  };

  return (
    <Drawer.Root position='bottom' size='90%' opened={open} onClose={handleClose}>
      <Drawer.Overlay />
      <Drawer.Content bg='gray.0' data-testid={testIds.drawerContent}>
        <Drawer.Header h={60} bg='gray.0'>
          <Title order={4}>{t('settings.title')}</Title>
          <Drawer.CloseButton data-testid={testIds.closeDrawerButton} />
        </Drawer.Header>
        <Drawer.Body h='calc(100% - 60px)'>
          <Stack className='overflow-auto' gap='md' pb='md' h='100%'>
            <Title order={5}>{t('settings.fields.appSettings.title')}</Title>
            <LanguageSelect value={settings.language} onChange={handleLanguageChange} />
            <DateFormatSelect value={settings.dateFormat} onChange={handleChange('dateFormat')} />
          </Stack>
          <Flex className='shrink-0' pos='fixed' bottom={0} left={0} bg='gray.0' w='100%' justify='center'>
            <AppInfo />
          </Flex>
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};
