import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Menu, UnstyledButton } from '@mantine/core';
import { IconLogout, IconCircleX, IconSettings } from '@tabler/icons-react';
import { names, useSpy } from 'services/espionage';
import { useAuthStore } from 'stores/auth';
import { getInitials } from 'utils';
import { modalManager } from 'services/modalManager';
import { config } from 'services/config';
import { useFlowStore } from 'stores/flow';
import { useParams } from 'react-router-dom';
import { useForceNavigate } from 'hooks/useForceNavigate';
import { ROUTES } from 'routes/routes.config';
import { useDistraction } from 'stores/app';
import { MenuOptionId } from './UserAvatar.types';
import { SettingsDrawer } from './SettingsDrawer/SettingsDrawer';

const flowApiUrl = config.getApiUrl();

export const testIds = {
  userAvatar: 'navbar-userAvatar',
  menuItem: (id: string) => `navbar-menuItem-${id}`,
};

export const UserAvatar = () => {
  const { executionId } = useParams();
  const { logout, currentUser } = useAuthStore(['logout', 'currentUser']);
  const { cancelExecution } = useFlowStore(['cancelExecution']);
  const { distracting, distract, concentrate } = useDistraction('settings');
  const forceNavigate = useForceNavigate();
  const { spyClick, spyMount, spyUnmount } = useSpy();
  const { t } = useTranslation();

  const handleOpenSettings = async () => {
    spyClick(names.UserMenu.OpenSettings);
    distract();
  };

  const handleLogout = () => {
    spyClick(names.UserMenu.Logout);
    modalManager.warning({
      icon: IconLogout,
      title: t('navbar.logoutModal.title'),
      message: t('navbar.logoutModal.message'),
      labels: {
        cancel: t('common.cancel'),
        confirm: t('navbar.logoutModal.confirm'),
      },
      onOpen: () => spyMount(names.LogoutModal.self),
      onConfirm: () => {
        spyClick(names.LogoutModal.Accept);
        logout();
        window.location.replace(`${flowApiUrl}/users/logout`);
      },
      onCancel: () => spyClick(names.LogoutModal.Cancel),
      onClose: () => spyUnmount(names.LogoutModal.Close),
    });
  };

  const handleCancelExecution = () => {
    spyClick(names.UserMenu.CancelFlowExecution);
    modalManager.warning({
      icon: IconCircleX,
      title: t('navbar.cancelInspectionModal.title'),
      message: t('navbar.cancelInspectionModal.message'),
      labels: {
        cancel: t('common.cancel'),
        confirm: t('common.accept'),
      },
      onOpen: () => spyMount(names.CancelFlowExecutionModal.self),
      onConfirm: async () => {
        if (executionId) {
          spyClick(names.CancelFlowExecutionModal.Accept);
          const success = await cancelExecution(executionId);
          if (success) forceNavigate(ROUTES.FLOWS);
        }
      },
      onCancel: () => spyClick(names.CancelFlowExecutionModal.Cancel),
      onClose: () => spyUnmount(names.CancelFlowExecutionModal.Close),
    });
  };

  const menuItems = useMemo(
    () => [
      ...(executionId
        ? [
            {
              id: MenuOptionId.CancelExecution,
              label: t('navbar.cancelFlow'),
              icon: <IconCircleX />,
              onClick: handleCancelExecution,
            },
          ]
        : []),
      {
        id: MenuOptionId.Settings,
        label: t('navbar.settings'),
        icon: <IconSettings />,
        onClick: handleOpenSettings,
      },
      {
        id: MenuOptionId.Logout,
        label: t('navbar.logout'),
        icon: <IconLogout />,
        onClick: handleLogout,
      },
    ],
    [executionId],
  );

  const onOpen = () => {
    spyClick(names.Navbar.OpenUserMenu);
    spyMount(names.UserMenu.self);
  };

  const fullName = currentUser ? `${currentUser.givenName} ${currentUser.familyName}` : '';

  return (
    <>
      <Menu position='bottom-end' onOpen={onOpen} onClose={() => spyUnmount(names.UserMenu.Close)}>
        {currentUser && (
          <Menu.Target>
            <UnstyledButton w={44} h={44} className='group flex items-center justify-end'>
              <Avatar
                data-testid={testIds.userAvatar}
                variant='filled'
                color='green.9'
                classNames={{
                  placeholder:
                    'group-[&[data-expanded]]:!bg-black group-[&[data-expanded]]:!text-white transition-colors',
                }}
              >
                {getInitials(fullName)}
              </Avatar>
            </UnstyledButton>
          </Menu.Target>
        )}

        <Menu.Dropdown>
          {menuItems.map((item) => (
            <Menu.Item
              key={item.id}
              className='text-[14px]'
              onClick={item.onClick}
              leftSection={item.icon}
              data-testid={testIds.menuItem(item.id)}
            >
              {item.label}
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
      <SettingsDrawer open={distracting} onClose={concentrate} />
    </>
  );
};
