import { ActionIcon } from '@mantine/core';
import { IconMicrophoneFilled } from '@tabler/icons-react';
import { names, useSpy } from 'services/espionage';
import { useOnline } from 'stores/network';
import { ReportCollectionKey } from 'stores/report';
import { splitReportCollectionKey } from 'stores/report/report.utils';
import { useVoiceStore } from 'stores/voice';

interface ContextRecordingButtonProps {
  reportKey: ReportCollectionKey;
  disabled?: boolean;
}

export const testIds = {
  getButtonTestId: (reportKey: ReportCollectionKey) => `context-recording-button-${reportKey}`,
};

export const ContextRecordingButton = ({ reportKey, disabled }: ContextRecordingButtonProps) => {
  const { changeVoiceState, internal, currentVoiceStatus } = useVoiceStore([
    'changeVoiceState',
    'internal',
    'currentVoiceStatus',
  ]);
  const online = useOnline();
  const { spyClick } = useSpy();
  const isDisabled = disabled || !online;

  const onClick = () => {
    const [containerId, eventDefinitionId] = splitReportCollectionKey(reportKey);
    spyClick(names.Container.OpenContextReporting, { containerId, eventId: eventDefinitionId });
    const context = { containerId, eventDefinitionId };
    const listening = currentVoiceStatus === 'listening';
    if (listening) internal.setContext(context);
    else changeVoiceState('open', context);
  };

  return (
    <ActionIcon radius='xl' size='lg' color='blue' variant='subtle' disabled={isDisabled} onClick={onClick}>
      <IconMicrophoneFilled
        className='drop-shadow-md'
        fill={isDisabled ? 'currentColor' : 'url(#aiola-gradient-blue)'}
        data-testid={testIds.getButtonTestId(reportKey)}
      />
    </ActionIcon>
  );
};
