import { FailedAction, PendingAction } from '@flow/flow-backend-types';

export enum DeviceOrientation {
  Landscape = 'landscape',
  Portrait = 'portrait',
}

export enum AppLayout {
  Custom = 'custom view',
  Regular = 'regular view',
}

export interface FailedActionWithReason {
  action: PendingAction;
  reason: FailedAction;
}
