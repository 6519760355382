import { Hermes, HermesSocket } from '@aiola/frontend';
import { ClientEvents, OpenSocketParams, ServerEvents } from './audioSocket.types';

export class AudioSocket {
  private static instance: AudioSocket;
  private socket?: HermesSocket<ServerEvents, ClientEvents>;

  private constructor() {}

  public static getInstance(): AudioSocket {
    if (!AudioSocket.instance) AudioSocket.instance = new AudioSocket();
    return AudioSocket.instance;
  }

  public async open(params: OpenSocketParams) {
    const { socketUrl, onError, onTranscript, onConnect } = params;
    const { uri, path, query } = socketUrl;
    return new Promise<void>((resolve, reject) => {
      setTimeout(() => reject(new Error('Audio socket connection timeout! ⏰')), 5000);
      try {
        if (!this.socket) {
          const socket = Hermes.socket<ServerEvents, ClientEvents>(uri, path, { query });
          socket.on('connect', () => {
            this.handleConnect(onConnect);
            resolve();
          });
          socket.io.on('reconnect', () => this.handleReconnect());
          socket.on('disconnect', (reason) => this.handleAutoDisconnect(reason));
          socket.on('error', (error) => {
            this.handleError(error, onError);
            reject(error);
          });
          socket.on('connect_error', (error) => {
            this.handleError(error.toString(), onError);
            reject(error);
          });
          socket.on('transcript', ({ transcript }) => onTranscript(transcript));
          this.socket = socket;
        } else {
          console.info('Tried to open an already opened audio socket! ✋');
          resolve();
        }
      } catch {
        reject(new Error('Error starting audio socket manually, Socket already exists! 🛑'));
      }
    });
  }

  public async close() {
    return new Promise<void>((resolve, reject) => {
      try {
        if (this.socket && this.socket.connected) {
          this.socket.on('disconnect', (reason) => {
            this.handleDisconnect(reason);
            resolve();
          });
          this.socket.close();
          this.socket = undefined;
        } else {
          console.info('Tried to close an already closed audio socket! ✋');
          resolve();
        }
      } catch {
        reject(new Error('Error closing audio socket manually, Socket is not available! 🛑'));
      }
    });
  }

  public sendMessage<Key extends keyof ClientEvents>(channel: Key, ...data: Parameters<ClientEvents[Key]>) {
    if (this.socket?.connected) {
      this.socket.emit(channel, ...data);
    }
  }

  get status() {
    const { socket } = this;
    if (!socket) return 'closed';
    return socket.connected ? 'opened' : 'closed';
  }

  private handleConnect(onConnect?: () => void) {
    onConnect?.();
    console.info('🎙️ Audio socket opened manually! ✅');
  }

  private handleReconnect(onReconnect?: () => void) {
    onReconnect?.();
    console.info('🎙️ Audio socket automatically reconnected! 🔄');
  }

  private handleDisconnect(reason: string, onDisconnect?: () => void) {
    onDisconnect?.();
    console.info('🎙️ Audio socket shut down manually! 🚫', reason);
  }

  private handleAutoDisconnect(reason: string, onDisconnect?: () => void) {
    onDisconnect?.();
    console.info('🎙️ Audio socket automatically disconnected! 🟠', reason);
  }

  private handleError(error: string, onError?: (error: string) => void) {
    onError?.(error);
    console.error(error);
  }
}
