import { Flex, Title, UnstyledButton, Loader } from '@mantine/core';
import { IconChevronLeft } from '@tabler/icons-react';
import { PropsWithChildren, useState } from 'react';
import { To } from 'react-router-dom';
import { useForceNavigate } from 'hooks/useForceNavigate';
import { useSpy } from 'services/espionage';
import { BACK_PAGE_INDEX } from 'utils';

interface BackHeaderButtonProps extends PropsWithChildren {
  biEventName: string;
  destination?: To;
  onClick?: () => Promise<boolean>;
}

export const testIds = {
  linkButton: 'back-header-link-button',
  iconChevron: 'back-header-icon-chevron',
  loader: 'back-header-loader',
};

export const BackHeaderButton = ({ children, biEventName, destination, onClick }: BackHeaderButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useForceNavigate();
  const { spyClick } = useSpy();

  const navigateBack = () => {
    if (destination) navigate(destination);
    else navigate(BACK_PAGE_INDEX);
  };

  const handleClick = async () => {
    spyClick(biEventName);
    if (onClick) {
      setIsLoading(true);
      const result = await onClick();
      if (result) navigateBack();
      setIsLoading(false);
    } else {
      navigateBack();
    }
  };

  return (
    <UnstyledButton onClick={handleClick} data-testid={testIds.linkButton}>
      <Flex align='center'>
        {isLoading ? (
          <Loader size={24} data-testid={testIds.loader} />
        ) : (
          <IconChevronLeft size={24} data-testid={testIds.iconChevron} />
        )}
        {typeof children === 'string' ? (
          <Title order={5} fw={400}>
            {children}
          </Title>
        ) : (
          children
        )}
      </Flex>
    </UnstyledButton>
  );
};
