import { useTranslation } from 'react-i18next';
import { Box, Stack, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useMap } from '@mantine/hooks';
import cn from 'classnames';
import { VoiceErrorReason } from 'stores/voice';
import { VoiceBarState } from './VoiceBar.types';
import classes from './VoiceBarText.module.css';

interface VoiceTextProps {
  state: VoiceBarState;
  error?: VoiceErrorReason;
}

export const testIds = {
  list: 'voice-bar-text-list',
  getStateTextTestId: (state: string) => `voice-bar-text-${state}`,
};

export const VoiceBarText = ({ state, error }: VoiceTextProps) => {
  const { t } = useTranslation();
  const [stateIndex, setStateIndex] = useState(1);
  const texts = useMap<VoiceBarState | VoiceErrorReason, [string, string?]>([
    ['offline', [t('voice.offline')]],
    ['standby', [t('voice.standby')]],
    ['active', [t('voice.listening')]],
  ]);

  useEffect(() => {
    const keys = Array.from(texts.keys());
    if (error) {
      const hasError = texts.has(error);
      if (!hasError) texts.set(error, [t(`voice.errors.${error}.title`), t(`voice.errors.${error}.message`)]);
      const index = hasError ? keys.findIndex((key) => key === error) : texts.size - 1;
      setStateIndex(index);
    } else {
      const index = keys.findIndex((key) => key === state);
      setStateIndex(index);
    }
  }, [state, error]);

  return (
    <Box className={cn(classes.wrapper)}>
      <Stack gap='zero' className={classes.list} style={{ '--index': stateIndex }} data-testid={testIds.list}>
        {Array.from(texts.entries()).map(([key, [title, message]]) => (
          <Box key={key} className={classes.text} data-testid={testIds.getStateTextTestId(key)}>
            <Text>{title}</Text>
            {message && (
              <Text span c='gray.5' size='sm'>
                {message}
              </Text>
            )}
          </Box>
        ))}
      </Stack>
    </Box>
  );
};
