import { useEffect, useRef } from 'react';
import { ActionIcon, Box, Flex, Overlay, Paper, ScrollArea, Text, Transition } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { exists } from 'utils';
import { names, useSpy } from 'services/espionage';
import { useContextualUiEvent } from './useContextualUiEvent';

export const testIds = {
  text: 'contextual-text-event-input',
  overlay: 'contextual-text-event-overlay',
  button: 'contextual-text-event-button',
};

export const ContextualTextEvent = () => {
  const scrollAnchorRef = useRef<HTMLDivElement>(null);
  const { spyMount, spyClick } = useSpy();
  const { containerId, uiEvent, value, resetContext } = useContextualUiEvent();
  const { eventId } = uiEvent ?? {};
  const opened = exists(uiEvent);

  const scrollToBottom = () => scrollAnchorRef.current?.scrollIntoView({ behavior: 'smooth' });

  const onAccept = () => {
    spyClick(names.ContextReportingModal.Accept, { containerId, eventId });
    resetContext?.();
  };

  const onClose = () => {
    spyClick(names.ContextReportingModal.Close);
    resetContext?.();
  };

  useEffect(() => {
    if (opened) spyMount(names.ContextReportingModal.self, { containerId, eventId });
  }, [opened]);

  useEffect(scrollToBottom, [value, opened]);

  return (
    <>
      <Transition duration={200} transition='fade' mounted={opened}>
        {(style) => <Overlay zIndex={10} style={style} onClick={onClose} data-testid={testIds.overlay} />}
      </Transition>
      <Transition duration={200} transition='pop' mounted={opened} onEntered={scrollToBottom}>
        {(style) => (
          <Paper
            withBorder
            className='fixed z-20 w-11/12 top-1/2 left-1/2 !-translate-x-1/2 !-translate-y-1/2 !min-h-[225px] !max-h-[400px] p-3 pb-9 flex'
            style={style}
            data-testid={testIds.text}
          >
            <ScrollArea>
              <Text size='lg'>{value}</Text>
              <Box ref={scrollAnchorRef} />
            </ScrollArea>
            <Flex pos='absolute' bottom={0} left={0} justify='end' w='100%' p='tn'>
              <ActionIcon
                size='sm'
                radius='xl'
                variant='gradient'
                gradient={{ from: 'green', to: 'cyan', deg: 200 }}
                onClick={onAccept}
              >
                <IconCheck size={10} stroke={3} data-testid={testIds.button} />
              </ActionIcon>
            </Flex>
          </Paper>
        )}
      </Transition>
    </>
  );
};
