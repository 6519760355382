import { Select } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { languageOptions } from 'stores/settings';

interface LanguageSelectProps {
  value: string;
  onChange: (value: string) => void;
}

export const testIds = {
  label: 'settings-language-label',
  option: 'settings-language-option',
  input: 'settings-language-input',
};

export const LanguageSelect = ({ value, onChange }: LanguageSelectProps) => {
  const { t } = useTranslation();

  return (
    <Select
      size='lg'
      checkIconPosition='right'
      value={value}
      label={t('settings.fields.appSettings.language')}
      labelProps={{ 'data-testid': testIds.label }}
      data={Object.entries(languageOptions).map(([code, label]) => ({
        value: code,
        label,
        'data-testid': testIds.option,
      }))}
      allowDeselect={false}
      onChange={(v) => onChange(v!)}
      data-testid={testIds.input}
    />
  );
};
