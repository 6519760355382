import { useState } from 'react';
import { Button } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { GenerationMethod, ReportValueType } from '@flow/flow-backend-types';
import { useFlowStore } from 'stores/flow';
import { ReportDynamicData, useReportStore } from 'stores/report';
import { modalManager } from 'services/modalManager';
import { names, useSpy } from 'services/espionage';
import { ROUTES } from 'routes/routes.config';
import { useForceNavigate } from 'hooks/useForceNavigate';
import { useMissingRequiredEvents } from 'pages/InspectionPage/InspectionPage.hooks';
import { splitReportCollectionKey } from 'stores/report/report.utils';

export const testIds = {
  completeButton: 'finish-inspection-button',
};

interface FinishInspectionProps {
  executionId: string;
}

export const FinishInspection = ({ executionId }: FinishInspectionProps) => {
  const { t } = useTranslation();
  const { reviewExecution, currentExecutionId } = useFlowStore(['reviewExecution', 'currentExecutionId']);
  const [loading, setLoading] = useState(false);
  const { send } = useReportStore(['send']);
  const { withDefault, withoutDefault } = useMissingRequiredEvents();
  const { spyMount, spyClick, spyUnmount } = useSpy();
  const navigate = useForceNavigate();

  const navigateToReviewPage = async () => {
    const execution = await reviewExecution(executionId);
    if (execution) navigate(ROUTES.REVIEW_INSPECTION(executionId));
  };

  const reportDefaultValues = async () => {
    if (withDefault.size > 0) {
      const reports = Array.from(withDefault.entries()).map<ReportDynamicData>(([key, uiEvent]) => {
        const [containerId, eventId] = splitReportCollectionKey(key);
        const { type, defaultValue } = uiEvent;
        const reportedValue =
          type === 'MultiSelectEvent' ? JSON.stringify({ add: [defaultValue], remove: [] }) : defaultValue;
        return {
          containerId,
          eventDefId: eventId,
          reportedValue,
          reportedValueType: ReportValueType.STRING,
          flowExecutionId: currentExecutionId,
          generationMethod: GenerationMethod.USER_ACTION,
        } as ReportDynamicData;
      });
      await send(reports);
    }
  };

  const reportingAllUnreportedContainers = async () => {
    spyClick(names.DefaultModal.Confirm);
    setLoading(true);
    await reportDefaultValues();
    navigateToReviewPage();
    setLoading(false);
  };

  const finishInspection = () => {
    spyClick(names.ExecutionPage.Complete);
    if (withoutDefault.size > 0) {
      modalManager.info({
        title: t('inspection.mandatoryContainers.title'),
        message: t('inspection.mandatoryContainers.message'),
        labels: { confirm: t('common.confirm'), cancel: t('common.cancel') },
        cancelProps: { display: 'none' },
        onConfirm: () => spyClick(names.MandatoryModal.Confirm),
        onCancel: () => spyClick(names.MandatoryModal.Cancel),
        onOpen: () => spyMount(names.MandatoryModal.self),
        onClose: () => spyUnmount(names.MandatoryModal.Close),
      });
    } else if (withDefault.size > 0) {
      modalManager.info({
        title: t('inspection.unreportedContainers.title'),
        message: t('inspection.unreportedContainers.message'),
        labels: { confirm: t('common.confirm'), cancel: t('common.cancel') },
        onConfirm: reportingAllUnreportedContainers,
        onCancel: () => spyClick(names.DefaultModal.Cancel),
        onOpen: () => spyMount(names.DefaultModal.self),
        onClose: () => spyUnmount(names.DefaultModal.Close),
      });
    } else navigateToReviewPage();
  };

  return (
    <Button
      className='shrink-0'
      size='md'
      loading={loading}
      onClick={finishInspection}
      data-testid={testIds.completeButton}
    >
      {t('inspection.completeBtn')}
    </Button>
  );
};
