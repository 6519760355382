import { Flex } from '@mantine/core';
import { LandscapeExecutionDetails, LandscapeNavbar, PortraitExecutionDetails, PortraitNavbar } from 'layouts';
import { BackHeaderButton } from 'components';
import { names } from 'services/espionage';
import { ROUTES } from 'routes/routes.config';
import { useAppStore } from 'stores/app';
import { FinishInspection } from '../FinishInspection/FinishInspection';

interface InspectionNavbarProps {
  executionId: string;
  flowName?: string;
  uniqueIdentifier?: string;
}

export const InspectionNavbar = ({ flowName, uniqueIdentifier, executionId }: InspectionNavbarProps) => {
  const { isLandscape } = useAppStore(['isLandscape']);

  return isLandscape ? (
    <LandscapeNavbar
      leftSection={<BackHeaderButton biEventName={names.ExecutionPage.Back} destination={ROUTES.FLOWS} />}
      rightSection={<FinishInspection executionId={executionId} />}
      underLogoSection={<LandscapeExecutionDetails flowTitle={flowName} uniqueIdentifier={uniqueIdentifier} />}
    />
  ) : (
    <Flex justify='space-between' gap='xs' direction='column'>
      <PortraitNavbar />
      <Flex justify='space-between' gap='xs' pr='tn'>
        <BackHeaderButton biEventName={names.ExecutionPage.Back} destination={ROUTES.FLOWS}>
          <PortraitExecutionDetails flowTitle={flowName} uniqueIdentifier={uniqueIdentifier} />
        </BackHeaderButton>
        <FinishInspection executionId={executionId} />
      </Flex>
    </Flex>
  );
};
