import React, { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import { useParams } from 'react-router-dom';
import { ExecutionRouteParams } from 'routes/routes.config';
import { names, useSpy } from 'services/espionage';
import { useContainerStore } from 'stores/container';
import { ImageReportValue, useReporter } from 'stores/report';
import { ReportedImage } from 'stores/uiEvent';
import { getImageSize, noop } from 'utils';
import { getImageUrl, imageApi } from 'services/api';
import { ReportValueType } from '@flow/flow-backend-types';
import { useTranslation } from 'react-i18next';
import { modalManager } from 'services/modalManager';
import { IconTrash } from '@tabler/icons-react';
import { toaster } from 'services/toaster';
import { ReportedImageData, imageDataToReport, resolveImagesData } from './ItemPhotoContext.utils';

const ImageEditor = React.lazy(() =>
  import('components/Image/ImageEditor').then((module) => ({ default: module.ImageEditor })),
);

interface ItemPhotoContextState {
  containerId?: string;
  imageData: ReportedImageData[];
  /**
   * Is loading upload of newly captured image?
   */
  loadingUpload: boolean;
  /**
   * Is loading new image that was received from a report?
   */
  loadingNewReport: boolean;

  uploadImage: (base64Image: string) => Promise<void>;
  deleteImage: (imageId: string) => void;
  openEditor: (imageId: string) => void;
}

const itemPhotoContextDefaultValues: ItemPhotoContextState = {
  containerId: undefined,
  imageData: [],
  loadingUpload: false,
  loadingNewReport: false,
  uploadImage: noop,
  deleteImage: noop,
  openEditor: noop,
};

const ItemPhotoContext = createContext<ItemPhotoContextState>(itemPhotoContextDefaultValues);

interface ItemPhotoProviderProps extends PropsWithChildren {
  containerId: string;
}

export const ItemPhotoProvider = ({ containerId, children }: ItemPhotoProviderProps) => {
  const { t } = useTranslation();
  const { executionId } = useParams() as ExecutionRouteParams;
  const imageEventId = useContainerStore((state) => state.containers[containerId]?.uiEvents?.imageEventId);
  const { spyMount, spyUnmount, spyClick } = useSpy();
  const { lastReport, triggerReport } = useReporter({ executionId, containerId, eventId: imageEventId! });
  const [imageData, setImageData] = useState<ReportedImageData[]>([]);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loadingEditUpload, setLoadingEditUpload] = useState(false);

  const resolveReportedImages = async (reportedImages: ReportedImage[]) => {
    const newData = await resolveImagesData(reportedImages, imageData, executionId, containerId);
    setImageData(newData);
  };

  // resolve images in case of incoming report
  useEffect(() => {
    resolveReportedImages(lastReport?.extraDetails?.images ?? []);
  }, [lastReport?.extraDetails?.images]);

  const addNewImageToData = async (imageId: string, originalId: string, base64Image: string) => {
    const [width, height] = await getImageSize(base64Image);
    const newImageData: ReportedImageData = {
      id: imageId,
      originalId,
      originalUrl: getImageUrl(originalId, executionId, containerId),
      originalBase64: base64Image,
      width,
      height,
      error: false,
    };
    setImageData((data) => [...data, newImageData]);
  };

  const onUploadError = () =>
    toaster.error({
      title: t('flows.images.uploadError.title'),
      message: t('flows.images.uploadError.message'),
    });

  const uploadImage = async (base64Image: string) => {
    setLoadingUpload(true);
    const newOriginalId = await imageApi.createImage({ executionId, containerId, base64Image });
    if (newOriginalId) {
      const newImageId = nanoid(4);
      await addNewImageToData(newImageId, newOriginalId, base64Image);
      const prevImages = lastReport?.extraDetails?.images ?? [];
      const newReportedImage: ReportedImage = { id: newImageId, original: newOriginalId };
      triggerReport({
        reportedValue: ImageReportValue.CREATE,
        reportedValueType: ReportValueType.STRING,
        extraDetails: { images: [...prevImages, newReportedImage] },
      });
    } else onUploadError();
    setLoadingUpload(false);
  };

  const [editedImageId, setEditedImageId] = useState<string | null>(null);
  const imageDataBeingEdited = useMemo(
    () => imageData.find((data) => data.id === editedImageId),
    [imageData, editedImageId],
  );

  const openEditor = (imageId: string) => {
    setEditedImageId(imageId);
  };

  const closeEditor = () => {
    setEditedImageId(null);
  };

  const removeEditedImageFromData = (imageId: string) => {
    setImageData((data) =>
      data.map((image) =>
        image.id === imageId
          ? {
              ...image,
              editedId: undefined,
              editedUrl: undefined,
              editedBase64: undefined,
            }
          : image,
      ),
    );
  };

  const restoreOriginal = async () => {
    if (!imageDataBeingEdited) return;
    if (imageDataBeingEdited.editedId) {
      setLoadingEditUpload(true);
      const deleteSuccessful = await imageApi.deleteImage({
        executionId,
        containerId,
        imageIds: [imageDataBeingEdited.editedId],
      });
      if (deleteSuccessful) {
        removeEditedImageFromData(imageDataBeingEdited.id);
        const updatedImages = imageDataToReport(imageData).map((image) =>
          imageDataBeingEdited.id === image.id ? { ...image, edited: undefined } : image,
        );
        triggerReport({
          reportedValue: ImageReportValue.EDIT,
          reportedValueType: ReportValueType.STRING,
          extraDetails: { images: updatedImages },
        });
      }
      setLoadingEditUpload(false);
    }
    setEditedImageId(null);
  };

  const placeEditedImageInData = (imageId: string, editedId: string, editedBase64: string) => {
    const editedUrl = getImageUrl(editedId, executionId, containerId);
    setImageData((data) =>
      data.map((image) =>
        image.id === imageId
          ? {
              ...image,
              editedId,
              editedUrl,
              editedBase64,
            }
          : image,
      ),
    );
  };

  const onEdit = async (base64Image?: string) => {
    if (!imageDataBeingEdited || !base64Image) return;
    setLoadingEditUpload(true);
    const newEditedImageId = await imageApi.updateImage({
      executionId,
      containerId,
      imageId: imageDataBeingEdited.editedId,
      originalFileId: imageDataBeingEdited.originalId,
      base64Image,
    });
    if (newEditedImageId) {
      placeEditedImageInData(imageDataBeingEdited.id, newEditedImageId, base64Image);
      const updatedImages = imageDataToReport(imageData).map((image) =>
        image.id === imageDataBeingEdited.id ? { ...image, edited: newEditedImageId } : image,
      );
      triggerReport({
        reportedValue: ImageReportValue.EDIT,
        reportedValueType: ReportValueType.STRING,
        extraDetails: { images: updatedImages },
      });
    } else onUploadError();
    setLoadingEditUpload(false);
    setEditedImageId(null);
  };

  const removeImageFromData = (imageId: string) => setImageData((data) => data.filter((image) => image.id !== imageId));

  const onDelete = async (imageId: string) => {
    spyClick(names.DeletePhotoModal.Delete, { containerId, fileId: imageId });
    const deletedImage = imageData.find((image) => image.id === imageId);
    if (!deletedImage) return;
    const { originalId, editedId } = deletedImage;
    const imageIds = editedId ? [editedId, originalId] : [originalId];
    removeImageFromData(imageId);
    const updatedImages = imageDataToReport(imageData).filter((image) => image.id !== deletedImage.id);
    triggerReport({
      reportedValue: ImageReportValue.DELETE,
      reportedValueType: ReportValueType.STRING,
      extraDetails: { images: updatedImages },
    });
    imageApi.deleteImage({ executionId, containerId, imageIds });
  };

  const deleteImage = (imageId: string) => {
    modalManager.warning({
      title: t('flows.images.confirmQuestion'),
      message: t('flows.images.confirmMessage'),
      onConfirm: () => onDelete(imageId),
      onCancel: () => spyClick(names.DeletePhotoModal.Cancel),
      onOpen: () => spyMount(names.DeletePhotoModal.self, { containerId, fileId: imageId }),
      onClose: () => spyUnmount(names.DeletePhotoModal.Close),
      icon: IconTrash,
      labels: {
        cancel: t('common.cancel'),
        confirm: t('common.delete'),
      },
    });
  };

  const loadingNewReport = (lastReport?.extraDetails?.images ?? []).length > imageData.length;
  const state = useMemo<ItemPhotoContextState>(
    () => ({
      containerId,
      imageData,
      loadingUpload,
      loadingNewReport,
      uploadImage,
      openEditor,
      deleteImage,
    }),
    [loadingUpload, loadingNewReport, imageData],
  );

  return (
    <ItemPhotoContext.Provider value={state}>
      {children}
      {imageDataBeingEdited && (
        <React.Suspense>
          <ImageEditor
            containerId={containerId}
            imageId={editedImageId}
            imageSrc={imageDataBeingEdited.editedBase64 || imageDataBeingEdited.originalBase64}
            loading={loadingEditUpload}
            onClose={closeEditor}
            onClear={restoreOriginal}
            onEdit={onEdit}
          />
        </React.Suspense>
      )}
    </ItemPhotoContext.Provider>
  );
};

export function usePhotoContext() {
  return useContext(ItemPhotoContext);
}
