import { FormEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button, Flex, ScrollArea } from '@mantine/core';
import { ExecutionRouteParams, ROUTES } from 'routes/routes.config';
import { useFlowByExecutionId } from 'stores/flow';
import { MetaType, useFlowMeta } from 'stores/inspectionMeta';
import { PageWrapper, BackHeaderButton } from 'components';
import { useForceNavigate } from 'hooks/useForceNavigate';
import { names } from 'services/espionage';
import { RenderMetaField } from './RenderMetaField/RenderMetaField';

export const testIds = {
  pageContainer: 'post-inspection-page-container',
  title: 'post-inspection-page-title',
  submit: 'post-inspection-page-submit',
};

const META_TYPE = MetaType.POST;

export const PostInspectionPage = () => {
  const { executionId } = useParams() as ExecutionRouteParams;
  const navigate = useForceNavigate();
  const flow = useFlowByExecutionId(executionId);
  const { metaFields, preValues, postValues } = useFlowMeta(META_TYPE, flow?.id, executionId);
  const { t } = useTranslation();

  const onSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    // TODO end execution here?
    navigate(ROUTES.FLOWS); // TODO navigate where tho?
  };

  return (
    <PageWrapper data-testid={testIds.pageContainer}>
      <BackHeaderButton biEventName={names.MetaPage.Back} destination={ROUTES.INSPECTION(executionId)}>
        {flow?.name}
      </BackHeaderButton>
      <header data-testid={testIds.title}>{t('meta.post.title', { name: flow?.name })}</header>
      <form className='grow flex flex-col min-h-0 pb-2' onSubmit={onSubmit}>
        <ScrollArea className='grow min-h-0'>
          <Flex pb={4} direction='column' gap={16}>
            {metaFields.map((field) => (
              <RenderMetaField
                key={field.id}
                field={field}
                defaultValue={postValues[field.id] ?? preValues[field.id]}
              />
            ))}
          </Flex>
        </ScrollArea>
        <Button type='submit' size='lg' className='shrink-0' data-testid={testIds.submit}>
          {t('meta.post.submit')}
        </Button>
      </form>
    </PageWrapper>
  );
};
