import { Select } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { dateFormatOptions } from 'stores/settings';

interface DateFormatSelectProps {
  value: string;
  onChange: (value: string) => void;
}

export const testIds = {
  label: 'settings-date-format-label',
  option: 'settings-date-format-option',
  input: 'settings-date-format-input',
};

export const DateFormatSelect = ({ value, onChange }: DateFormatSelectProps) => {
  const { t } = useTranslation();

  return (
    <Select
      size='lg'
      checkIconPosition='right'
      value={value}
      label={t('settings.fields.appSettings.dateFormat')}
      labelProps={{ 'data-testid': testIds.label }}
      data={Object.entries(dateFormatOptions).map(([df, label]) => ({
        value: df,
        label,
        'data-testid': testIds.option,
      }))}
      allowDeselect={false}
      onChange={(v) => onChange(v!)}
      data-testid={testIds.input}
    />
  );
};
