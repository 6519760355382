import { useTranslation } from 'react-i18next';
import { Box, Button, Flex, Stack, Title, Text, Image } from '@mantine/core';
import { AiolaIconDark } from 'assets';
import { names, useSpy } from 'services/espionage';
import { useAuthStore } from 'stores/auth';
import { config } from 'services/config';
import { useEffect } from 'react';
import { SplashVideo } from './components/SplashVideo/SplashVideo';

export const testIds = {
  loginButton: 'loginButton',
  title: 'title',
  logo: 'logo',
  splashVideo: 'splashVideo',
  disclaimerText: 'disclaimerText',
  disclaimerLogo: 'disclaimerLogo',
};

export const LoginPage = () => {
  const { login } = useAuthStore(['login']);
  const { t } = useTranslation();
  const { spyPageview, spyClick } = useSpy();

  const { disclaimerText, disclaimerLogoUrl } = config;
  const showDisclaimer = disclaimerText || disclaimerLogoUrl;

  useEffect(() => {
    spyPageview(names.LoginPage.self);
  }, []);

  const onLogin = () => {
    spyClick(names.LoginPage.Login);
    login();
  };

  return (
    <Box pos='relative' w='100vw' h='100vh' className='overflow-hidden'>
      <SplashVideo />
      <Flex
        pos='absolute'
        right={0}
        top={0}
        w='100%'
        h='100%'
        pb={20}
        pt={60}
        px={16}
        direction='column'
        justify='space-between'
        align='center'
        className='z-[20]'
        data-testid={testIds.title}
      >
        <Flex direction='column' gap={1} align='center'>
          <Title order={3}>{t('login.welcomeTitle')}</Title>
          <AiolaIconDark className='w-[75px] sm:w-[120px] mx-2' data-testid={testIds.logo} />
          <Title order={3}>{t('login.flowTitle')}</Title>
        </Flex>
        <Stack w='100%' gap={16}>
          {showDisclaimer && (
            <Stack gap={8}>
              <Image
                src={disclaimerLogoUrl}
                alt='disclaimer logo'
                className='w-[160px] h-[32px] mx-auto'
                data-testid={testIds.disclaimerLogo}
              />
              <Text
                c='gray.6'
                size='xl'
                className='text-center text-ellipsis overflow-hidden'
                data-testid={testIds.disclaimerText}
              >
                {disclaimerText}
              </Text>
            </Stack>
          )}
          <Button onClick={onLogin} data-testid={testIds.loginButton}>
            {t('login.loginButton')}
          </Button>
        </Stack>
      </Flex>
    </Box>
  );
};
