import { PointerEventHandler, useCallback, useEffect, useRef } from 'react';
import { useDocumentVisibility, useSetState } from '@mantine/hooks';

const DRAG_THRESHOLD = 40;
export function useDrawerDrag() {
  const visible = useDocumentVisibility();
  const initialClientY = useRef<number>(0);
  const [{ dragging, opened, lastMoveDirection, lastMoveDistance }, setDragState] = useSetState({
    lastMoveDirection: 0,
    lastMoveDistance: 0,
    dragging: false,
    opened: false,
  });

  const close = useCallback(() => setDragState({ opened: false }), []);

  const dragStart: PointerEventHandler<HTMLDivElement> = useCallback((event) => {
    setDragState({ dragging: true });
    initialClientY.current = event.clientY;
  }, []);

  const dragMove: PointerEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (!dragging) return;
      event.preventDefault();
      const distance = initialClientY.current - event.clientY;
      setDragState({ lastMoveDirection: Math.sign(event.movementY), lastMoveDistance: distance });
    },
    [dragging],
  );

  const dragStop: PointerEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (!dragging) return;
      const finalDistance = initialClientY.current - event.clientY;
      const shouldToggle = Math.abs(finalDistance) > DRAG_THRESHOLD;
      const directionIsUp = lastMoveDirection < 0;
      if (shouldToggle) setDragState({ opened: directionIsUp });
      setDragState({ lastMoveDistance: 0, dragging: false });
    },
    [dragging, lastMoveDirection],
  );

  useEffect(() => {
    setDragState({ dragging: false });
  }, [visible]);

  return { dragStart, dragMove, dragStop, close, lastMoveDistance, opened, dragging };
}
