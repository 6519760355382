import { useCallback, useEffect } from 'react';
import { useMap } from '@mantine/hooks';
import { useVoiceStore } from 'stores/voice';
import { nanoid } from 'nanoid';

const CUTOFF = 0.01;
const MIN = 1.5;
const MAX = 3;

export function usePulses() {
  const { currentRms: rms = 0 } = useVoiceStore(['currentRms']);
  const ripples = useMap<string, number>([]);

  /** Clear the pulse matching `id`.
   * If omitted, clear all pulses. */
  const clearPulse = useCallback((id?: string) => {
    if (id) ripples.delete(id);
    else ripples.clear();
  }, []);

  useEffect(() => {
    if (rms > CUTOFF) {
      const scaled = (MAX + 1 - MIN) * rms + MIN;
      ripples.set(nanoid(4), Math.min(scaled, MAX));
    }
  }, [rms]);

  return { pulses: Array.from(ripples.entries()), clearPulse };
}
