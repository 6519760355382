import { Flex, ScrollArea } from '@mantine/core';
import { VoiceBar } from '..';
import classes from '../../InspectionPage.module.css';
import { InspectionActionButtons } from '../InspectionActionButtons/InspectionActionButtons';
import { InspectionListContent } from '../InspectionListContent/InspectionListContent';

export const testIds = {
  inspectionMainContent: 'inspection-main-content',
  containerListContainer: 'container-list-container',
};

export interface InspectionMainContentProps {
  collapseContainerDetails?: boolean;
  displayContainerContent?: boolean;
  showEmptyState?: boolean;
}

export const InspectionMainContent = ({
  collapseContainerDetails,
  displayContainerContent,
  showEmptyState = false,
}: InspectionMainContentProps) => (
  <>
    <Flex direction='column' gap='lg' px='sm' className={classes.headerBox} data-testid={testIds.inspectionMainContent}>
      <InspectionActionButtons />
    </Flex>
    <ScrollArea
      type='scroll'
      className='grow'
      classNames={{ viewport: 'scroll-p-3 scroll-smooth' }}
      mb='var(--voice-bar-height, 0px)'
      data-testid={testIds.containerListContainer}
    >
      <InspectionListContent
        collapseContainerDetails={collapseContainerDetails}
        displayContainerContent={displayContainerContent}
        showEmptyState={showEmptyState}
      />
    </ScrollArea>
    <VoiceBar />
  </>
);
