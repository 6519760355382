import { useVoiceStore, VoiceErrorReason, VoiceStatus } from 'stores/voice';
import { useOnline } from 'stores/network';
import { useEffect } from 'react';
import { VoiceBarState } from '../VoiceBar.types';

function getVoiceState(online: boolean, status: VoiceStatus, error?: VoiceErrorReason): VoiceBarState {
  if (!online) return 'offline';
  if (error) return 'error';
  if (status === 'paused') return 'standby';
  if (status === 'listening') {
    return 'active';
    // if too loud return WARNING
  }
  return 'standby';
}

export function useVoiceState() {
  const online = useOnline();
  const { currentVoiceStatus, voiceError, duringVoiceTransition, setVoiceError, isMicPermitted } = useVoiceStore([
    'currentVoiceStatus',
    'voiceError',
    'duringVoiceTransition',
    'setVoiceError',
    'isMicPermitted',
  ]);

  useEffect(() => {
    if (voiceError === 'offlineError' && online) setVoiceError(undefined);
  }, [online]);

  return {
    state: getVoiceState(online, currentVoiceStatus, voiceError),
    error: voiceError,
    loading: duringVoiceTransition,
    permitted: Boolean(isMicPermitted),
  };
}
