import { useFilteredContainers } from 'stores/container';
import { Flex } from '@mantine/core';
import { ContainerParent } from '../ContainerParent/ContainerParent';
import { ContainerItem } from '../ContainerItem/ContainerItem';

interface ContainerListProps {
  containerIds: string[];
  level?: number;
  collapseContainerDetails?: boolean;
  displayContainerContent?: boolean;
}

export const ContainerList = ({
  containerIds,
  level = 0,
  collapseContainerDetails,
  displayContainerContent,
}: ContainerListProps) => {
  const containers = useFilteredContainers(containerIds);

  return (
    <Flex direction='column' gap='tn' py={12} px={10}>
      {containers.map(({ container, filteredContainersAmount }) =>
        container.childrenIds?.length ? (
          <ContainerParent
            key={container.id}
            container={container}
            filteredContainersAmount={filteredContainersAmount}
            level={level}
            collapseContainerDetails={collapseContainerDetails}
            displayContainerContent={displayContainerContent}
          />
        ) : (
          <ContainerItem
            key={container.id}
            container={container}
            collapseContainerDetails={collapseContainerDetails}
            displayContent={collapseContainerDetails}
          />
        ),
      )}
    </Flex>
  );
};
