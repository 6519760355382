import { BiEvent, BiEventAgentFields, BiEventArgs, BiEventAction } from '@flow/flow-backend-types';
import mixpanel from 'mixpanel-browser';
import { useParams } from 'react-router-dom';
import { useCallback } from 'react';

import { useAppStore, DeviceOrientation, AppLayout } from 'stores/app';
import { useFlowStore } from 'stores/flow';
import { useOnline } from 'stores/network';
import { db } from 'services/db';
import { config } from 'services/config';

import { getAgentInformation, getScreenResolution } from './espionage.utils';
import { espionageApi } from './espionage.api';

const { Landscape, Portrait } = DeviceOrientation;
const { Custom, Regular } = AppLayout;
/**
 * Spy on a user interaction in the app, providing partial information.
 * The spy will complete some static information and send it to the backend.
 * A response should not be awaited.
 *
 * @example
 * sendSpyReport({
 *   name: names.key.value,
 *   value: 'some_value',
 *   flow_id: 'some_flow_id',
 *   flow_version: 'some_flow_version',
 *   execution_id: 'some_execution_id',
 * });
 */
export function storeSpyReport(args: BiEventArgs & Pick<BiEventAgentFields, 'is_offline'>) {
  const device_orientation = window.screen.orientation?.type.includes(Landscape) ? Landscape : Portrait;
  const message: BiEvent = {
    ...args,
    device_orientation,
    value: typeof args.value === 'object' ? JSON.stringify(args.value) : args.value,
    device_timestamp_utc: Date.now(),
    device_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    screen_resolution: getScreenResolution(),
    page_url: window.location.href,
    ...getAgentInformation(),
  };
  db.pendingBiEvents.put(message);
}

/**
 * Get a spy function that sends a BI event to the backend.
 * The spy receives a required event name, and an optional value.
 */
export function useSpy() {
  const { flowId, executionId } = useParams();
  const { isLandscape } = useAppStore(['isLandscape']);
  const flow = useFlowStore((state) => (flowId ? state.flows[flowId] : null));
  const execution = useFlowStore((state) => (executionId ? state.executions[executionId] : null));
  const online = useOnline();

  const spyEvent = useCallback(
    (action: BiEventAction) => (name: string, value?: unknown) => {
      if (executionId && !execution) console.error(`BI reported on execution ${executionId} but it does not exist`);
      if (flowId && !flow) console.error(`BI reported on flow ${flowId} but it does not exist`);
      storeSpyReport({
        name,
        action,
        value,
        app_layout: isLandscape ? Custom : Regular,
        flow_id: flowId ?? execution?.flowRef.id,
        flow_version: flow?.activeVersion ?? execution?.flowRef.version,
        execution_id: executionId,
        is_offline: !online,
      });
    },
    [flowId, executionId, isLandscape, online, execution, flow],
  );

  return {
    spyClick: spyEvent('click'),
    spyPageview: spyEvent('pageview'),
    spyMount: spyEvent('mount'),
    spyUnmount: spyEvent('unmount'),
    spySwipe: spyEvent('swipe'),
  };
}

export async function syncPendingBiEvents() {
  try {
    const pendingEventsCollection = db.pendingBiEvents.toCollection();
    const pendingEvents = await pendingEventsCollection.offset(0).limit(config.biEventsBatchLimit).toArray();
    if (pendingEvents.length === 0) return;

    const keysToDelete = await pendingEventsCollection.keys();

    // Mixpanel client SDK does not support list of events in track method
    // the events will be sent using batching by Mixpanel in the background
    pendingEvents.forEach((event) => mixpanel.track(event.name, event));

    await espionageApi.postReports(pendingEvents);
    await db.pendingBiEvents.bulkDelete(keysToDelete);
  } catch (error) {
    console.error('Error syncing pending BI events:', error);
  }
}
