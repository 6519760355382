import { useEffect } from 'react';
import { useAppStore } from 'stores/app';
import { useVoiceStore } from 'stores/voice';

export function usePause() {
  const { changeVoiceState } = useVoiceStore(['changeVoiceState']);
  const { distractions } = useAppStore(['distractions']);

  useEffect(() => {
    if (distractions.has('background')) changeVoiceState('close');
    else if (distractions.size) changeVoiceState('pause');
    else changeVoiceState('resume');
  }, [distractions.size]);
}
