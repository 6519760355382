import { buildCypher } from './espionage.cypher';

const nameEntries = [
  { Navbar: ['OpenUserMenu'] },
  { UserMenu: ['OpenSettings', 'CancelFlowExecution', 'Logout', 'Close'] },
  { SettingsDrawer: ['SelectLanguage', 'SelectDateFormat', 'Close'] },
  { LanguageModal: ['Save', 'Cancel', 'Close'] },
  { CancelFlowExecutionModal: ['Accept', 'Cancel', 'Close'] },
  { LogoutModal: ['Accept', 'Cancel', 'Close'] },
  { Screen: ['PageOrientation'] },
  { App: ['Initialization'] },
  { LoginPage: ['Login'] },
  { FlowListPage: ['Tab', 'SelectFlow', 'SelectExecution'] },
  { MetaPage: ['Back', 'SetField', 'Submit'] },
  {
    ExecutionPage: [
      'Back',
      'Complete',
      'OpenEventFilter',
      'NewDynamicContainer',
      'RenderModelError',
      'ToggleContainerGroup',
    ],
  },
  { VoiceWidget: ['Activate', 'Release', 'PTT_Start', 'PTT_Finish', 'SwipeTranscript'] },
  { TranscriptDrawer: ['Close'] },
  { Camera: ['TakePhoto'] },
  { VoiceMode: ['Change', 'Info', 'Cancel', 'Close'] },
  { VoiceFileModal: ['Confirm', 'Cancel', 'Close'] },
  { EventFilterDrawer: ['Apply', 'Reset', 'Close'] },
  { MandatoryModal: ['Confirm', 'Cancel', 'Close'] },
  { DefaultModal: ['Confirm', 'Cancel', 'Close'] },
  {
    Container: [
      'Focus',
      'Applicability',
      'OpenCamera',
      'OpenEventLogs',
      'Done',
      'ManualEditEvent',
      'ManualEditEventDone',
      'DeletePhoto',
      'EditPhoto',
      'OpenContextReporting',
    ],
  },
  { DeletePhotoModal: ['Delete', 'Cancel', 'Close'] },
  { EditPhoto: ['Confirm', 'Erase', 'Close'] },
  { EventLogsDrawer: ['Close'] },
  { ReviewPage: ['Tab', 'Back', 'Next'] },
  { SummaryPage: ['Rating', 'Back', 'Submit'] },
  { ContextReportingModal: ['Accept', 'Close'] },
] as const;

/**
 * A cypher object containing a nested tree of names as required by for BI events.
 */
export const names = buildCypher(nameEntries);
