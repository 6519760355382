import { defaultSettings, SETTINGS_LOCAL_STORAGE_KEY } from './settings.const';
import { SettingsValues } from './settings.types';

export function saveSettings(settings: SettingsValues) {
  localStorage.setItem(SETTINGS_LOCAL_STORAGE_KEY, JSON.stringify(settings));
}

export function retrieveSettings(): SettingsValues {
  const settings = localStorage.getItem(SETTINGS_LOCAL_STORAGE_KEY) ?? '{}';
  const parsedSettings = JSON.parse(settings) ?? {};
  return { ...defaultSettings, ...parsedSettings };
}
