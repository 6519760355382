import { useCallback } from 'react';
import { useAppStore } from './app.store';

export function useDistraction(id: string) {
  const { distractions, addDistraction, removeDistraction } = useAppStore([
    'distractions',
    'addDistraction',
    'removeDistraction',
  ]);
  const distracting = distractions.has(id);
  const distract = useCallback(() => addDistraction(id), []);
  const concentrate = useCallback(() => removeDistraction(id), []);
  return { distracting, distract, concentrate };
}
