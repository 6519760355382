export const SelectExecution = {
  JOIN: 'Join',
  CONTINUE: 'Continue',
  NO_ACTION: 'No Action',
} as const;

export const NewDynamicContainerLocation = {
  BUTTON: 'Add new',
  ICON: 'Empty list',
} as const;

export const ToggleContainerGroupStatus = {
  EXPAND: 'Expand',
  COLLAPSE: 'Collapse',
} as const;
