import { MEDIA_CONSTRAINTS, AUDIO_PARAMS } from 'services/voice/recorder/recorder.const';
import { AudioFrame, WorkletMessageEvent } from 'services/voice/recorder/recorder.types';

const { SAMPLE_RATE } = AUDIO_PARAMS;

export class Recorder {
  private static instance: Recorder;
  private audioContext: AudioContext;
  private mediaStream?: MediaStream;
  private audioProcessorWorklet?: AudioWorkletNode;

  private constructor() {
    this.audioContext = new AudioContext({ sampleRate: SAMPLE_RATE });
    this.audioContext.suspend();
  }

  public static getInstance(): Recorder {
    if (!Recorder.instance) Recorder.instance = new Recorder();
    return Recorder.instance;
  }

  get status() {
    return this.audioContext.state === 'running' ? 'opened' : 'closed';
  }

  async start(onAudioFrame: (audioFrame: AudioFrame) => void) {
    const { audioContext, audioProcessorWorklet } = this;
    try {
      if (audioContext.state === 'suspended' && !audioProcessorWorklet) {
        await audioContext.resume();

        this.mediaStream = await navigator.mediaDevices.getUserMedia(MEDIA_CONSTRAINTS);
        const micNode = audioContext.createMediaStreamSource(this.mediaStream);
        await audioContext.audioWorklet.addModule('/worklet/audio-processor.js');

        this.audioProcessorWorklet = new AudioWorkletNode(audioContext, 'audio-processor');
        this.audioProcessorWorklet.port.onmessage = (event: WorkletMessageEvent) => onAudioFrame(event.data);
        micNode.connect(this.audioProcessorWorklet).connect(audioContext.destination);
      } else {
        console.info('Tried to start an already started recorder! ✋');
      }
    } catch {
      throw new Error('Error starting the audio recorder! 🛑');
    }
  }

  async stop() {
    const { audioContext, audioProcessorWorklet } = this;
    try {
      if (audioContext.state === 'running' && audioProcessorWorklet) {
        this.mediaStream?.getTracks().forEach((track) => track.stop());

        audioProcessorWorklet.port.postMessage('stop');
        audioProcessorWorklet.disconnect();
        this.audioProcessorWorklet = undefined;

        // Suspend the AudioContext to effectively pause the recording process
        await this.audioContext.suspend();
      } else {
        console.info('Tried to stop a stopped recorder! ✋');
      }
    } catch {
      throw new Error('Error stopping the audio recorder! 🛑');
    }
  }
}
