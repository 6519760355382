import { ActionIcon, Flex, MantineColor, Text, Title } from '@mantine/core';
import { IconProps } from '@tabler/icons-react';
import { FC, ReactNode, useEffect } from 'react';

interface DefaultModalContentProps {
  Icon?: FC<IconProps>;
  color?: MantineColor;
  title: ReactNode;
  message?: ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
}

export const testIds = {
  title: 'default-modal-content-title',
  message: 'default-modal-content-message',
  icon: 'default-modal-content-icon',
};

export const DefaultModalContent = ({ Icon, color, title, message, onOpen, onClose }: DefaultModalContentProps) => {
  useEffect(() => {
    onOpen?.();
    return () => {
      onClose?.();
    };
  }, []);

  return (
    <Flex direction='column' align='center' gap='tiny' mb={40} data-testid={testIds.title}>
      <Title size={17} pos='relative'>
        {Icon && (
          <ActionIcon variant='transparent' pos='absolute' left={-40} top={-3} color={color} data-testid={testIds.icon}>
            <Icon size={24} />
          </ActionIcon>
        )}
        {title}
      </Title>
      {message && (
        <Text ta='center' size='md' c='gray.6' data-testid={testIds.message}>
          {message}
        </Text>
      )}
    </Flex>
  );
};
