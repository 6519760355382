import { DateFormat } from 'interfaces';
import { config } from 'services/config';
import { formatPlaceholder } from 'utils';
import { isMobile } from 'react-device-detect';
import { SettingsKeys } from './settings.types';

export const SETTINGS_LOCAL_STORAGE_KEY = 'aiola-user-settings';

export const languageOptions = {
  'en-us': '🇺🇸 English',
  'fr-ca': '🇨🇦 Français',
  'zh-cn': '🇨🇳 中文',
  'pt-br': '🇧🇷 Português',
  id: '🇮🇩 Bahasa Indonesia',
} as const;

const dateFormats: DateFormat[] = [
  { order: ['M', 'D', 'Y'], separator: '/' },
  { order: ['D', 'M', 'Y'], separator: '/' },
  { order: ['M', 'D', 'Y'], separator: '-' },
  { order: ['D', 'M', 'Y'], separator: '-' },
  { order: ['M', 'D', 'Y'], separator: '.' },
  { order: ['D', 'M', 'Y'], separator: '.' },
];

export const dateFormatOptions = dateFormats.reduce<Record<string, string>>((acc, format) => {
  const dateFormat = formatPlaceholder(format);
  acc[dateFormat] = dateFormat;
  return acc;
}, {});

export const dateFormatValues = dateFormats.reduce<Record<string, DateFormat>>((acc, format) => {
  acc[formatPlaceholder(format)] = format;
  return acc;
}, {});

export const canVibrate = isMobile && Boolean(navigator.vibrate);

export const defaultSettings = {
  language: languageOptions[config.defaultLanguage as keyof typeof languageOptions]
    ? config.defaultLanguage
    : Object.keys(languageOptions)[0],
  dateFormat: formatPlaceholder(dateFormats[0]),
  vibrationsEnabled: canVibrate,
  soundsEnabled: true,
} satisfies Record<SettingsKeys, unknown>;
