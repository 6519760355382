import { ScreenResolution, BiEvent } from '@flow/flow-backend-types';
import {
  osName,
  osVersion,
  deviceType,
  browserName,
  browserVersion,
  mobileModel,
  mobileVendor,
} from 'react-device-detect';

export const getScreenResolution = (): ScreenResolution => `${window.screen.width}x${window.screen.height}`;

export function getAgentInformation(): Pick<BiEvent, 'os' | 'device' | 'platform' | 'browser'> {
  return {
    os: `${osName} ${osVersion}`,
    device: `${mobileVendor} ${mobileModel}`,
    platform: deviceType,
    browser: `${browserName} ${browserVersion}`,
  };
}
