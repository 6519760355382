import { useListState } from '@mantine/hooks';
import { useVoiceStore } from 'stores/voice';
import { useEffect } from 'react';
import { nanoid } from 'nanoid';
import { Transcript, VoiceBarState } from '../VoiceBar.types';

export function useTranscripts(state: VoiceBarState) {
  const [transcripts, { append, setItemProp }] = useListState<Transcript>([]);
  const { transcript } = useVoiceStore(['transcript']);

  useEffect(() => {
    const shouldAddNew = !transcripts.length || Boolean(transcripts.at(-1)?.transcript);
    // add new piece of transcript if last transcript not empty
    if (state === 'active' && shouldAddNew) append({ id: nanoid(4), transcript: '' });
  }, [state]);

  useEffect(() => {
    if (transcripts.length && transcript) setItemProp(transcripts.length - 1, 'transcript', transcript);
  }, [transcript]);

  return transcripts;
}
