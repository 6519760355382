import { Modal, Flex } from '@mantine/core';
import { useDynamicModalStore } from 'stores/dynamicModal';
import { useDistraction } from 'stores/app';
import { useEffect } from 'react';
import { ModalHeader } from './ModalHeader';
import { ModalBody } from './ModalBody';
import { ModalFooter } from './ModalFooter';

export const testIds = {
  container: 'dynamic-modal-container',
  header: {
    title: 'dynamic-modal-title',
    xButton: 'dynamic-modal-xButton',
  },
  body: {
    content: 'dynamic-modal-content',
  },
  footer: {},
};

export const DynamicModal = () => {
  const { distracting, distract, concentrate } = useDistraction('dynamic-modal');
  const { modalLayout } = useDynamicModalStore(['modalLayout']);

  useEffect(() => {
    if (!modalLayout) return concentrate();

    const { timeout } = modalLayout;
    const timerId = timeout ? setTimeout(concentrate, timeout) : undefined;
    distract();

    return () => {
      if (timerId) clearTimeout(timerId);
      concentrate();
    };
  }, [modalLayout]);

  return (
    <Modal
      centered
      padding={0}
      withCloseButton={false}
      closeOnClickOutside={false}
      opened={distracting}
      onClose={concentrate}
    >
      <Flex direction='column' data-testid={testIds.container}>
        {modalLayout?.header && <ModalHeader data={modalLayout.header} />}
        {modalLayout?.body && <ModalBody data={modalLayout.body} />}
        {modalLayout?.footer && <ModalFooter data={modalLayout.footer} />}
      </Flex>
    </Modal>
  );
};
