import { DynamicPopup } from '@flow/flow-backend-types';
import { createStoreHook } from '@aiola/frontend';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { flowStore } from 'stores/flow';

interface DynamicModalState {
  modalLayout: DynamicPopup | null;
}

interface DynamicModalActions {
  setLayout: (layout: DynamicPopup) => void;
  reset: () => void;
}

const initialState: DynamicModalState = {
  modalLayout: null,
};

export const dynamicModalStore = create(
  immer<DynamicModalState & DynamicModalActions>((set) => ({
    ...initialState,
    setLayout: (layout) => {
      const { currentExecutionId } = flowStore.getState();
      if (layout.flowExecutionId !== currentExecutionId) return;
      set({ modalLayout: layout });
    },
    reset: () => {
      set(initialState);
    },
  })),
);

export const useDynamicModalStore = createStoreHook<DynamicModalState & DynamicModalActions>({
  store: dynamicModalStore,
  useShallow,
});
