import { useEffect } from 'react';
import { Drawer, Flex, Text } from '@mantine/core';
import { names, useSpy } from 'services/espionage';
import { useContainerById, useContainerStore } from 'stores/container';
import { useTranslation } from 'react-i18next';
import { useAppStore } from 'stores/app';
import { INSPECTION_PAGE_LANDSCAPE_PERCENTAGE } from 'consts';
import { LogItem } from '../LogItem/LogItem';
import classes from './ContainerReportsLog.module.css';
import { useLogItems } from './ContainerReportLog.hooks';

export const testIds = {
  drawerContainer: 'drawerContainer',
  drawerTitle: 'drawerTitle',
  drawerCloseButton: 'drawerCloseButton',
  containerName: 'containerName',
};

const { LEFT_SECTION_WIDTH } = INSPECTION_PAGE_LANDSCAPE_PERCENTAGE;

export const ContainerReportsLog = () => {
  const { t } = useTranslation();
  const { spyMount, spyClick } = useSpy();
  const { closeContainerLog, openedLogContainerId } = useContainerStore(['closeContainerLog', 'openedLogContainerId']);
  const logContainer = useContainerById(openedLogContainerId);
  const { isLandscape } = useAppStore(['isLandscape']);
  const reports = useLogItems(openedLogContainerId);

  useEffect(() => {
    if (logContainer) spyMount(names.EventLogsDrawer.self, openedLogContainerId);
  }, [logContainer]);

  const onClickClose = () => {
    spyClick(names.EventLogsDrawer.Close);
    closeContainerLog();
  };

  return (
    <Drawer
      opened={!!logContainer}
      position={isLandscape ? 'left' : 'bottom'}
      size={isLandscape ? LEFT_SECTION_WIDTH : '90%'}
      withOverlay={!isLandscape}
      lockScroll={!isLandscape}
      closeButtonProps={{ 'data-testid': testIds.drawerCloseButton } as any}
      title={
        <Flex direction='column' justify='center'>
          <Text fw='bold' ta='center' data-testid={testIds.drawerTitle}>
            {t('inspection.containerLog.title')}
          </Text>
          <Text ta='center' data-testid={testIds.containerName}>
            {logContainer?.title}
          </Text>
        </Flex>
      }
      classNames={{
        header: classes.logHeader,
        body: classes.logBody,
        title: classes.logTitle,
      }}
      onClose={onClickClose}
      data-testid={testIds.drawerContainer}
    >
      {reports.map((reportedEvent) => (
        <LogItem key={reportedEvent.id} reportedEvent={reportedEvent} />
      ))}
    </Drawer>
  );
};
