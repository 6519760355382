import { Card, Flex, Text, Title } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import { Flow } from '@flow/flow-backend-types';
import { useTranslation } from 'react-i18next';
import { names, useSpy } from 'services/espionage';

interface FlowItemProps {
  flow: Flow;
  onClick?: () => void;
}

export const testIds = {
  getItemCardId: (cardId: string) => `flowCard-${cardId}`,
  itemTitle: 'flow-title-name',
  itemTitleStatus: 'flow-title-status',
  flowAction: 'flow-action',
};

export const FlowItem = ({ flow, onClick }: FlowItemProps) => {
  const { t } = useTranslation();
  const { spyClick } = useSpy();

  const onClickByCard = () => {
    spyClick(names.FlowListPage.SelectFlow, flow.id);
    onClick?.();
  };

  return (
    <Card p='md' onClick={onClickByCard} data-testid={testIds.getItemCardId(flow.id)}>
      <Flex justify='space-between' align='center' gap='sm' mih={40}>
        <Title order={5} fw={400} data-testid={testIds.itemTitle}>
          {flow?.name}
        </Title>
        <Text className='flex gap-2 items-center' c='blue' fw={600} data-testid={testIds.flowAction}>
          {t('flows.action.start')}
          <IconChevronRight size={20} />
        </Text>
      </Flex>
    </Card>
  );
};
