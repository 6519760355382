import { Flex } from '@mantine/core';
import { LandscapeNavbar, PortraitNavbar } from 'layouts';
import { BackHeaderButton } from 'components';
import { names } from 'services/espionage';
import { ROUTES } from 'routes/routes.config';
import { useAppStore } from 'stores/app';

interface PreInspectionNavbarProps {
  flowName?: string;
}

export const PreInspectionNavbar = ({ flowName }: PreInspectionNavbarProps) => {
  const { isLandscape } = useAppStore(['isLandscape']);

  return isLandscape ? (
    <LandscapeNavbar
      leftSection={
        <BackHeaderButton biEventName={names.MetaPage.Back} destination={ROUTES.FLOWS}>
          {flowName}
        </BackHeaderButton>
      }
    />
  ) : (
    <Flex direction='column'>
      <PortraitNavbar />
      <BackHeaderButton biEventName={names.MetaPage.Back} destination={ROUTES.FLOWS}>
        {flowName}
      </BackHeaderButton>
    </Flex>
  );
};
