import { CoralogixRum } from '@coralogix/browser';
import { CoralogixDomain } from '@coralogix/browser/src/types';
import { CoralogixLogSeverity } from '@coralogix/browser/src/types-external';
import { config } from 'services/config';
import ILoggerProvider, { LogMessage, Severity, UserLoggerData } from '../logger.types';

const HEALTH_PING_URL = `${config.getApiUrl()}/${config.flowApiPrefix}/health`;
const suppress = Boolean(import.meta.env.VITE_SUPPRESS_LOGS);
class CoralogixLoggerProvider implements ILoggerProvider {
  constructor() {
    if (suppress) return;
    const { public_key, applicationName, coralogixDomain, environment } = this.getCoralogixConfig();
    const { appVersion } = config;

    CoralogixRum.init({
      public_key,
      application: applicationName,
      version: appVersion,
      coralogixDomain,
      environment,
      beforeSend: (logEntry) => {
        const context = logEntry?.resource_context?.name || logEntry?.network_request_context?.url;
        return context?.includes(HEALTH_PING_URL) ? null : logEntry;
      },
      sessionRecordingConfig: {
        enable: true,
        autoStartSessionRecording: true,
        recordConsoleEvents: true,
      },
    });
  }

  setUser(user: UserLoggerData): void {
    if (suppress) return;
    CoralogixRum.setUserContext({ user_id: user.userId, user_name: user.userName, user_email: user.userEmailAddress });
  }

  log(severity: Severity, logMsg: LogMessage): void {
    if (suppress) return;
    const { message, ...rest } = logMsg;
    CoralogixRum.log(this.getSeverity(severity), message, rest);
  }

  private getCoralogixConfig(): {
    public_key: string;
    applicationName: string;
    coralogixDomain: CoralogixDomain;
    environment: string;
  } {
    const public_key = config.coralogixApiKey;
    const applicationName = config.coralogixAppName;
    const appDomain = config.coralogixDomain as CoralogixDomain;
    const environment = import.meta.env.MODE;

    return { public_key, applicationName, coralogixDomain: appDomain, environment };
  }

  private getSeverity(severity: Severity): CoralogixLogSeverity {
    switch (severity) {
      case Severity.INFO:
        return CoralogixLogSeverity.Info;
      case Severity.WARN:
        return CoralogixLogSeverity.Warn;
      case Severity.ERROR:
        return CoralogixLogSeverity.Error;
      default:
        return CoralogixLogSeverity.Debug;
    }
  }
}
export default new CoralogixLoggerProvider();
