import { Box, Flex, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { config } from 'services/config';
import { useUserDateFormat } from 'stores/settings';
import { useFlowStore } from 'stores/flow';

export const testIds = {
  appInfo: 'app-info',
  releaseDate: 'release-date',
  appVersion: 'app-version',
  executionId: 'execution-id',
  dot: 'dot',
};

export const AppInfo = () => {
  const dateFormat = useUserDateFormat();
  const { currentExecutionId } = useFlowStore(['currentExecutionId']);

  const { appVersion, appVersionTime } = config;
  const appVersionDate = dayjs(appVersionTime).format(dateFormat);

  const dot = <Box bg='gray.5' className='rounded-full h-1 w-1' data-testid={testIds.dot} />;

  return (
    <Flex gap={4} justify='center' align='center' data-testid={testIds.appInfo}>
      <Text data-testid={testIds.releaseDate}>{appVersionDate}</Text>
      {dot}
      <Text data-testid={testIds.appVersion}>{appVersion}</Text>
      {currentExecutionId && (
        <>
          {dot}
          <Text data-testid={testIds.appVersion}>{currentExecutionId}</Text>
        </>
      )}
    </Flex>
  );
};
